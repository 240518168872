<template>

  <div>

    <skud-edit-token-add-new
      :is-add-new-skud-token-sidebar-active.sync="isAddNewSkudTokenSidebarActive"
      :skud-data="skudData"
      company-options="companyOptions"
      type-options="typeOptions"
      @refetch-skud="refetchSkud"
    />

    <b-tabs
      pills
    >

      <b-tab active>
        <template #title>
          <feather-icon
            icon="CpuIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('skuds.skud') }}</span>
        </template>
        <b-card>
          <!-- Media -->
          <b-media class="mb-2">
            <h4 class="mb-1">
              {{ skudData.name }}
            </h4>
          </b-media>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >

              <!-- Skud Info: Input Fields -->
              <b-form
                class="mb-2"
                @submit_.prevent="handleSubmit(onSubmit)"
              >
                <b-row>

                  <!-- Field: Skudname -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.skud_name')"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="skudData.name"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.status')"
                      label-for="skud-status"
                    >
                      <v-select
                        v-model="skudData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="skud-status"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: serial -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.number')"
                      label-for="number"
                    >
                      <b-form-input
                        id="num"
                        v-model="skudData.number"
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.card')"
                      label-for="card"
                    >
                      <b-form-input
                        id="card"
                        v-model="skudData.card"
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.owner')"
                      label-for="owner"
                    >
                      <b-form-input
                        id="owner"
                        v-model="skudData.owner"
                        autofocus
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.credit')"
                      label-for="credit"
                    >
                      <b-form-input
                        id="credit"
                        v-model="skudData.credit"
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.promo')"
                      label-for="promo"
                    >
                      <b-form-input
                        id="promo"
                        v-model="skudData.promo"
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.auto')"
                      label-for="auto"
                    >
                      <v-select
                        v-model="skudData.auto"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="auto"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.autoamount')"
                      label-for="autoamount"
                    >
                      <b-form-input
                        id="autoamount"
                        v-model="skudData.autoamount"
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.company')"
                      label-for="company"
                    >
                      <v-select
                        v-model="skudData.companyId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="companyOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="company"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('skuds.location')"
                      label-for="object"
                    >
                      <v-select
                        v-model="skudData.objectId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="objectOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="object"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>
              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
                type_="submit"
                @click="onSubmit"
              >
                {{ $t('common.save_changes') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('common.reset') }}
              </b-button>

            </b-col>
          </b-row>

        </b-card>
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import {
  // BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  // BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormCheckbox,
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm, BCard,
  // BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormCheckbox,
  BTab, BTabs,
  // BTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
// import { ref, watch } from '@vue/composition-api'
import { ref, onUnmounted } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import SkudEditTokenAddNew from '../skuds-list/SkudEditTokenAddNew.vue'

import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'
import useSkudsList from '../skuds-list/useSkudsList'

export default {
  components: {

    // SkudEditTokenAddNew,

    // BTable,

    BTab,
    BTabs,

    BButton,
    BMedia,
    // BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    // BInputGroup,
    // BInputGroupAppend,
    // BInputGroupPrepend,
    vSelect,
  },
  props: {
    skudData: {
      // type: Object,
      required: true,
      validator: p => ['object'].indexOf(typeof p) !== -1 || p === null,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'skudData.planogramId': function () {
      this.loadPLU(this.skudData.planogramId)
    },
  },
  setup(props, { emit }) {
    const {
      resolveSkudObjectText,
      objectOptions,
      companyOptions,
      typeOptions,
      planogramOptions,

      attrOptionsPLU,
      attrOptionsATTR,

      attrOptionsPLUtmp,
      attrOptionsATTRtmp,

      // loadATTR,
      // loadPLU,
      // saveATTR,
      deleteQR,

      qrtableColumns,

    } = useSkudsList(emit)

    const isAddNewSkudTokenSidebarActive = ref(false)

    const qrSearch = ref('')

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    // attrOptionsPLU.value = []
    // attrOptionsATTR.value = []

    // attrOptionsPLUtmp.value = []
    // attrOptionsATTRtmp.value = []

    const canSendPLU = ref(false)

    // loadATTR(router.currentRoute.params.id)

    const refetchSkud = () => {
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!! ???????????')
      emit('refetch-skud')
    }

    /* * /
    const funcLloadATTR = () => {
      refetchSkud()
      loadATTR(router.currentRoute.params.id)
    }
    /* */

    // Vue.prototype.$webSocketsConnect({ subscription: 'skud', id: router.currentRoute.params.id }, funcLloadATTR)

    onUnmounted(() => {
      Vue.prototype.$webSocketsDisconnect()
    })

    const permissionsData = ref([
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Skud',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ])

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.skudData.avatar = base64
    })

    const ok = {
      mac: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      // console.log(props.skudData)
      resOk.value = ok
      store.dispatch('app-skud/addSkud', props.skudData)
        .then(() => {
          router.push({ name: 'apps-skuds-list' })
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    const QR64 = ref(null)

    const toast = useToast()

    const customImage = item => {
      // console.log(i18n.t('common.for'))

      store.dispatch('app-skud/fetchQR64', item)
        .then(response => {
          QR64.value = response.data

          Vue.swal({
            title: `QR ${i18n.t('common.for')} ${item.name}(#${item.id})`,
            // eslint-disable-next-line global-require
            imageUrl: QR64.value,
            imageWidth: 350,
            imageHeight: 350,
            imageAlt: 'Custom image',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          // console.log(error)
          if (error.status === 400 && error.data.error.amount) {
            // console.log(error.data.error.amount[0])
            toast({
              component: ToastificationContent,
              props: {
                title: error.data.error.amount[0],
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
          QR64.value = undefined
        })
    }

    return {
      resolveSkudObjectText,
      avatarText,
      objectOptions,
      companyOptions,
      statusOptions,
      permissionsData,
      typeOptions,
      planogramOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      onSubmit,
      resOk,

      attrOptionsPLU,
      attrOptionsATTR,

      attrOptionsPLUtmp,
      attrOptionsATTRtmp,

      // loadATTR,
      // loadPLU,
      canSendPLU,

      qrtableColumns,
      isAddNewSkudTokenSidebarActive,

      refetchSkud,

      customImage,
      deleteQR,
      qrSearch,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
