import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import i18n from '@/libs/i18n'

export default function useSkudsList(emit) {
  // Use toast
  const toast = useToast()

  const refSkudListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'dataview',
      label: '',
      tdClass: 'npd_15_0',
      thClass: 'npd_15_0',
    },
    {
      key: 'name',
      label: i18n.t('skuds.skud_name'),
      formatter: title,
      sortable: true,
      tdClass: 'npd_0_0',
      thClass: 'npd_0_0',
    },
    {
      key: 'color',
      label: '',
      sortable: true,
      tdClass: 'npd_0_0',
      thClass: 'npd_0_0',
    },
    {
      key: 'credit',
      label: i18n.t('skuds.cred'),
      sortable: true,
    },
    {
      key: 'status',
      label: i18n.t('skuds.status'),
      sortable: true,
      // label: '',
      tdClass: 'npd_0_15',
      thClass: 'npd_0_15',
    },
    {
      key: 'company_id',
      label: i18n.t('skuds.company'),
      formatter: title,
      sortable: true,
      tdClass: 'npd_0_',
      thClass: 'npd_0_',
    },
    /*
    {
      key: 'object_id',
      label: 'Location',
      formatter: title,
      sortable: true,
    },
    { key: 'serial', sortable: true },
    */
    { key: 'type', label: `${i18n.t('skuds.type')}`, sortable: true },
    /*
    { key: 'mac', sortable: true },
    */
    {
      key: 'description',
      label: i18n.t('skuds.last_event'),
      formatter: title,
      sortable: true,
    },
    /*
    {
      key: 'event_time',
      label: 'Event time',
      formatter: title,
      sortable: true,
    },
    */
    /*
    {
      key: 'version',
      label: i18n.t('skuds.sw'),
      formatter: title,
      sortable: true,
    },
    */
    {
      key: 'actions',
      label: i18n.t('common.actions'),
    },
  ]

  const qrtableColumns = [
    {
      key: 'id',
      label: i18n.t('skuds.qr_id'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'name',
      label: i18n.t('skuds.qr_name'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'type',
      label: i18n.t('skuds.qr_type'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'amount',
      label: i18n.t('skuds.qr_amount'),
      formatter: title,
      sortable: false,
    },
    /*
    {
      key: 'remaining',
      label: 'Remaining amount',
      formatter: title,
      sortable: false,
    },
    */
    {
      key: 'date',
      label: i18n.t('skuds.qr_date'),
      formatter: title,
      sortable: false,
    },
    { key: 'actions', label: i18n.t('common.actions') },
  ]

  const perPage = ref(10)
  const totalSkuds = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSkudListTable.value ? refSkudListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSkuds.value,
    }
  })

  const refetchData = () => {
    // console.log(refSkudListTable.value)
    // refSkudListTable.value.localItems[0].alive = Date.now()
    refSkudListTable.value.refresh()
    // refSkudListTable.value.showLoading()
  }

  watch([currentPage, perPage, searchQuery, objectFilter, companyFilter, statusFilter], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchData()
  })

  const fetchSkuds = (ctx, callback) => {
    store
      .dispatch('app-skud/fetchSkuds', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        lang: i18n.locale,
      })
      .then(response => {
        const { skuds, total } = response.data

        callback(skuds)
        totalSkuds.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching skuds list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const exportSkudsFile = event => {
    const file = event.target.files[0]
    event.target.value = null
    const formData = new FormData()
    formData.append('file', file)
    store.dispatch('app-skud/exportSkuds', formData)
      .then(response => {
        Vue.swal({
          icon: 'success',
          title: 'Success',
          text: `Expoted ${response.total} new skuds.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid xlsx file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchSkudsFile = (type = 'xlsx') => {
    store
      .dispatch('app-skud/fetchSkuds', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
        lang: i18n.locale,
        type,
      })
      .then(response => {
        const { data } = response
        const buf = Buffer.from(data.data)
        const contentType = type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf'
        const blob = new Blob([buf], { type: contentType })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Skuds.${type}`
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching export file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const reFetchSkuds = () => {
    // console.log('REREFETCH MACHINES!')
    store
      .dispatch('app-skud/fetchSkuds', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        object: objectFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { skuds, total } = response.data
        refSkudListTable.value.localItems = skuds
        /*
        console.log(skuds)
        // refSkudListTable.value.localItems
        if (refSkudListTable.value.localItems.length > skuds.length) {
          // eslint-disable-next-line no-plusplus
          for (let index = skuds.length; index < refSkudListTable.value.localItems.length; index++) {
            delete refSkudListTable.value.localItems[index]
          }
        }
        skuds[0].alive = Date.now()
        refSkudListTable.value.localItems = skuds
        // eslint-disable-next-line no-plusplus
        // for (let index in skuds) {}
        // callback(skuds)
        */
        totalSkuds.value = total
      })
      .catch(() => {
      })
  }

  const deleteSkud = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Skud <b>${item.name}</b> will be deleted.\nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-skud/deleteSkud', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Skud ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Skud delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const deleteQR = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: i18n.t('swal.sure'),
      html: `${i18n.t('skuds.qr_code')} <b>${item.name}</b> ${i18n.t('swal.deleted_will')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: i18n.t('swal.yes'),
      cancelButtonText: i18n.t('swal.no'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-skud/deleteQR', { id: item.id })
          .then(() => {
            emit('refetch-skud')
            Vue.swal({
              icon: 'success',
              title: i18n.t('swal.deleted'),
              text: `${i18n.t('skuds.qr_code')} ${item.name} ${i18n.t('swal.deleted_has')}.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            // console.log(e)
            toast({
              component: ToastificationContent,
              props: {
                title: `${i18n.t('skuds.qr_code')} ${i18n.t('swal.delete_error')}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const duplicateSkud = item => {
    // console.log(Vue.swal)
    // console.log(item)
    Vue.swal({
      title: 'Are you sure?',
      html: `Skud <b>${item.name}</b> will be duplicated!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, duplicate it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-skud/duplicateSkud', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Duplicated!',
              text: `Skud ${item.name} has been deplecated.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Skud duplicate error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const objectOptions = ref([])
  store.dispatch('app-skud/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-skud/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const planogramOptions = ref([])
  const getPlanogramOptions = () => {
    store.dispatch('app-skud/fetchPlanograms')
      .then(response => {
        // console.log('RESP', response.data)
        planogramOptions.value = response.data.data
        // console.log('companyOptions', companyOptions)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching planogram list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  getPlanogramOptions()

  const typeOptions = [
    { label: i18n.t('common.day'), value: 'day' },
    { label: i18n.t('common.week'), value: 'week' },
    { label: i18n.t('common.month'), value: 'month' },
  ]

  const attrOptionsPLU = ref([])
  const attrOptionsATTR = ref([])

  const attrOptionsPLUtmp = ref([])
  const attrOptionsATTRtmp = ref([])

  const loadATTR = id => {
    store.dispatch('app-skud/fetchAttributes', { id })
      .then(response => {
        attrOptionsPLU.value = response.data.PLU
        attrOptionsATTR.value = response.data.ATTR
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching attributes for company',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const loadPLU = id => {
    store.dispatch('app-skud/fetchPLUs', { id })
      .then(response => {
        attrOptionsPLU.value = response.data.PLU
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching attributes for company',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // eslint-disable-next-line
  const saveATTR = (attr, skud, type, index) => {

    if (type === 'PLU') {
      Vue.swal({
        title: 'Are you sure?',
        html: `${i18n.t('planograms.planogram')} ${i18n.t('common.for')} <b>${skud.name}</b> will be updated or duplicated. \nDo you want update or duplicate?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('common.update'),
        cancelButtonText: i18n.t('common.duplicate'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          store.dispatch('app-skud/updateAttribute', {
            attr,
            id: skud.id,
            type,
            index,
            update: result.value,
            planogram: skud.planogramId,
          })
            .then(response => {
              if (skud.planogramId !== response.data.plan) getPlanogramOptions()

              emit('refetch-skud')
              loadPLU(response.data.plan)

              let name
              if (index === -1) {
                // eslint-disable-next-line
                name = 'ALL'
              } else {
                name = attr[index].name
              }

              toast({
                component: ToastificationContent,
                props: {
                  title: `PLU ${name} updated`,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              if (Array.isArray(attr)) {
                // eslint-disable-next-line
                attr = { name: 'ALL' }
              }

              toast({
                component: ToastificationContent,
                props: {
                  title: `Error updating ${attr.name} PLU`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    } else {
      store.dispatch('app-skud/updateAttribute', {
        attr,
        id: skud.id,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Attributes ${attr.name} updated`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Error updating ${attr.name} attribute`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  /*
  const loadATTR = id => {
    store.dispatch('app-skud/fetchAttributes', { id })
      .then(response => {
        // eslint-disable-next-line
        for (let qq in response.data.PLU) {
          // eslint-disable-next-line
          let tmp = {}
          // eslint-disable-next-line
          let tmp2 = {}
          // eslint-disable-next-line
          for(let k in response.data.PLU[qq]){
            tmp[k] = response.data.PLU[qq][k]
            tmp2[k] = response.data.PLU[qq][k]
          }
          attrOptionsPLU.value.push(tmp)
          attrOptionsPLUtmp.value.push(tmp2)
        }
        // eslint-disable-next-line
        for (let qq in response.data.ATTR) {
          // eslint-disable-next-line
          let tmp = {}
          // eslint-disable-next-line
          let tmp2 = {}
          // eslint-disable-next-line
          for(let k in response.data.ATTR[qq]){
            tmp[k] = response.data.ATTR[qq][k]
            tmp2[k] = response.data.ATTR[qq][k]
          }
          attrOptionsATTR.value.push(tmp)
          attrOptionsATTRtmp.value.push(tmp2)
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching attributes for company',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  */

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const autoOptions = [
    { label: i18n.t('common.day'), value: 'day' },
    { label: i18n.t('common.week'), value: 'week' },
    { label: i18n.t('common.month'), value: 'month' },
  ]

  const resolveSkudStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'primary'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveSkudCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveSkudObjectText = object => {
    if (!object) {
      return ''
    }
    try {
      return objectOptions.value.find(o => o.value === object).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchSkuds,
    fetchSkudsFile,
    exportSkudsFile,
    reFetchSkuds,
    tableColumns,
    qrtableColumns,
    perPage,
    currentPage,
    totalSkuds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSkudListTable,

    resolveSkudStatusVariant,
    resolveSkudCompanyText,
    resolveSkudObjectText,
    refetchData,

    // Extra Filters
    objectFilter,
    companyFilter,
    statusFilter,

    companyOptions,
    objectOptions,
    typeOptions,
    planogramOptions,

    deleteSkud,
    deleteQR,
    duplicateSkud,

    attrOptionsPLU,
    attrOptionsATTR,

    attrOptionsPLUtmp,
    attrOptionsATTRtmp,

    loadATTR,
    loadPLU,
    saveATTR,
    autoOptions,

  }
}
