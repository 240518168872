import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSkuds(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/skud/skuds', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSkud(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/skud/skuds/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSkud(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/skud/skuds/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQR(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/skud/QR64/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateSkud(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/skud/duplicate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSkud(ctx, skudData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/skud/skuds', { skud: skudData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    addSkudCode(ctx, tokenData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/skud/codes', { code: tokenData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },

    fetchQR64(ctx, tokenData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/skud/QR64', { code: tokenData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },

    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlanograms() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/planogram/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAttributes(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/attribute/attributes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPLUs(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/attribute/plus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAttribute(ctx, {
      attr, id, type, index, update, planogram,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/attribute/attributes/', {
            attr, id, type, index, update, planogram,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportSkuds(ctx, file) {
      return new Promise((resolve, reject) => {
        console.log(file)
        axios
          .post('/apps/skud/export', file, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
