<template>
  <component :is="skudData === undefined || true ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="skudData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching skud data
      </h4>
      <div class="alert-body">
        No skud found with this skud id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-skuds-list'}"
        >
          Skud List
        </b-link>
        for other skuds.
      </div>
    </b-alert>

    <skud-edit-tab-account
      :skud-data="skudData"
      class="mt-2 pt-75"
      @refetch-skud="refetchSkud"
    />

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
// import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import skudStoreModule from '../skudStoreModule'
import SkudEditTabAccount from './SkudEditTabAccount.vue'
// import SkudEditTabInformation from './SkudEditTabInformation.vue'
// import SkudEditTabSocial from './SkudEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    SkudEditTabAccount,
    // SkudEditTabInformation,
    // SkudEditTabSocial,
  },
  setup() {
    const skudData = ref(null /* { QRS: [] } */)

    const getData = () => {
      store.dispatch('app-skud/fetchSkud', { id: router.currentRoute.params.id })
        .then(response => { skudData.value = response.data })
        .catch(error => {
          if (error.response.status === 404) {
            skudData.value = null /* { QRS: [] } */
          }
        })
    }

    const USER_APP_STORE_MODULE_NAME = 'app-skud'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, skudStoreModule)
      // console.log('************************ WILL CONNECT')
      // Vue.prototype.$webSocketsConnect({ subscription: 'skud', id: router.currentRoute.params.id }, getData)
    }

    // console.log(Vue.prototype)
    // console.log(Vue.webSocketsService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      // Vue.prototype.$webSocketsDisconnect()
    })

    getData()

    const refetchSkud = () => {
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!!')
      getData()
    }

    return {
      skudData,
      refetchSkud,
    }
  },
}
</script>

<style>

</style>
